<template>
    <div>
        <div class="advertise__panel advertise__panel--mobile-no-bg advertise__lookup__panel">
            <div class="advertise__lookup advertise__lookup" v-show="!showForm && !showResult" :class="{showResult3: showResult}" >
                <div class="advertise__lookup_wrapper">
                    <form>
                        <label for="reg">Enter Reg Number</label>
                        <div class="advertise__lookup_input">
                            <!-- Do not remove single space placeholder -->
                            <input type="text" name="reg" id="reg" data-cy="reg-input" value="" placeholder=" " maxlength="12" @change="showLookupErr=false" v-model="registration"/>
                            <div v-if="lookupError" class="form-error">{{lookupError}}</div>
                            <small>Works with Irish and UK registrations</small>
                        </div>
                        <input type="submit"  data-cy="btn-lookup" value="Lookup" v-on:click="lookup"/>
                    </form>
                </div>
            </div>

            <div class="advertise__existing-ad" v-show="!showForm && !showResult">
                <router-link class="advertise__link" :to="{name: 'advertise-manage'}">Manage Existing Ads</router-link>
            </div>

            <div class="advertise__variants" v-show="showResult">
                <p class="text-info">We think this vehicle is the below variant. If we're wrong, please select a different option.</p>
                <div class="advertise__variants_wrapper">
                    <div class="advertise__variants_details">
                        <div class="advertise__variants_details_message"><div class="icon"></div> <div class="text">Details found for:</div></div>
                        <div class="list-container">
                            <h2>{{ this.registration }}</h2>
                            <div class="list-subcontainer">
                                <div>
                                    <ul>
                                        <li><span class="title">Make:</span> <span class="desc">{{ regLookup.make | capitalize}}</span></li>
                                        <li><span class="title">Model:</span> <span class="desc">{{ regLookup.model | capitalize }}</span></li>
                                        <li><span class="title">Variant:</span> <span class="desc">{{ regLookup.variant }}</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li><span class="title">Year:</span> <span class="desc">{{ regLookup.reg_year  }}</span></li>
                                        <li><span class="title">Engine:</span> <span class="desc">{{ engineCapacity > 1 ? engineCapacity + ' Litres' : engineCapacity + ' Litre' || 'n/a' }}</span></li>
                                        <li><span class="title">Fuel:</span> <span class="desc">{{ regLookup.fuel_type | capitalize  }}</span></li>
                                        <li><span class="title">Body:</span> <span class="desc">{{ regLookup.body_type | capitalize  }}</span></li>
                                        <li><span class="title">Transmission:</span> <span class="desc">{{ regLookup.transmission_type | capitalize }}</span></li>
                                        <li><span class="title">Doors:</span> <span class="desc">{{ lookupDoors || 'n/a' }}</span></li>
                                        <li><span class="title">Colour:</span> <span class="desc">{{ regLookup.colour | capitalize  }}</span></li>
                                        <li><span class="title">Owners:</span> <span class="desc">{{ regLookup.owners || 'n/a'}}</span></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="advertise__variants__buttons advertise__variants__buttons--mobileonly">
                    <div class="change-reg"><a href="#" v-on:click="disableResult()">Lookup a Different Registration Number</a></div>
                    <button class="advertise__variants__buttons__button" v-on:click="enableForm()" data-cy="btn-next">Next</button>
                </div>
                <div class="advertise__buttons advertise__buttons--desktoponly">
                    <div class="change-reg"><a href="#" v-on:click="disableResult()">Lookup a Different Registration Number</a></div>
                    <button class="advertise__button" v-on:click="enableForm()" data-cy="btn-next-desktop">Next</button>
                </div>
            </div>

            <div class="advertise__details" v-show="showForm">
                <div  class="form-error-top" v-if="Object.keys(errorResponse).length > 0">There was problem submitting. Please check below.   </div>
                <div  class="form-error-top" v-if="Object.keys(errorResponse500).length > 0">General problem. Please try again later.  </div>
                <p style="text-align:center; padding-bottom:2rem;" class="form-error" v-if="errorResponse.general_error">{{ errorResponse.general_error[0]}}</p>
                <div class="advertise__details_maintop">
                    <div class="advertise__details_maintopleft">
                        <h2 class="mobileonly">Car Details</h2>
                        <div class="advertise__details_field">
                            <label for="reg" class="required">Reg Number:</label>
                            <div>
                                <input type="text" name="reg" id="reg" value="" class="form-control" v-model="registration"/>
                                <p v-if="errorResponse.reg_number" class="form-error">{{ errorResponse.reg_number[0] }}</p>
                            </div>

                        </div>
                        <div class="advertise__details_field">
                            <label for="make" class="required">Make:</label>
                            <div>
                                <select name="make" id="make" class="form-control" :value="selectedMake" v-model="selectedMake" @change="loadModels">
                                    <option value="">Select Make</option>
                                    <option v-for="(make, idx) in makes" v-bind:key="idx"  :value="make.id">{{ make.make }}</option>
                                </select>
                                <p v-if="errorResponse.make_id" class="form-error">{{errorResponse.make_id[0]}}</p>
                            </div>
                        </div>
                        <div class="advertise__details_field">
                            <label for="model" class="required">Model:</label>
                            <div>
                                <select name="model" id="model" class="form-control" :value="selectedModel" v-model="selectedModel">
                                    <option value="">Select Model</option>
                                    <option v-for="(model, idx) in models" v-bind:key="idx"  :value="model.id">{{ model.model }}</option>
                                </select>
                                <p v-if="errorResponse.model_id" class="form-error">{{errorResponse.model_id[0]}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="advertise__details_maintopright">
                        <div class="advertise__details_message">
                            <h2>How to sell your car fast</h2>
                            <h3>Check what others are selling for and be competitive.</h3>
                            <p>Remember, a private ad will need to be at least 20% cheaper than Dealer ads to
                                achieve a quick sale.</p>
                        </div>
                    </div>
                </div>

                <div class="advertise__details_mainbottom">
                    <div class="advertise__details_mainbottomleft">
                        <div class="advertise__details_field">
                            <label for="reg" class="required">Variant:</label>
                            <div>
                                <input type="text" name="reg" id="reg" class="form-control" v-model="selectedVariant"/>
                                <p v-if="errorResponse.variant" class="form-error">{{errorResponse.variant[0]}}</p>
                            </div>
                        </div>
                        <div class="">
                            <div class="advertise__details_field">
                                <label for="year" class="required">Year:</label>
                                <div>
                                    <select name="year" id="year" class="form-control" :value="selectedYear" v-model="selectedYear">
                                        <option value="">Please Select</option>
                                        <option v-for="(year, idx) in getYearsRange()" v-bind:key="idx" :value="year">{{ year }}</option>
                                    </select>
                                    <p v-if="errorResponse.reg_year" class="form-error">{{errorResponse.reg_year[0]}}</p>
                                </div>
                            </div>
                            <div class="advertise__details_field">
                                <label for="transmission" class="required">Transmission:</label>
                                <div>
                                    <select name="transmission" id="transmission" class="form-control" :value="selectedTransmissionType" v-model="selectedTransmissionType">
                                        <option value="">Please Select</option>
                                        <option v-for="(val, idx) in transmissionTypes" v-bind:key="idx" :value="val.id">{{ val.transmission_type }}</option>
                                    </select>
                                    <p v-if="errorResponse.transmission_type_id" class="form-error">{{errorResponse.transmission_type_id[0]}}</p>
                                </div>
                            </div>

                        </div>
                        <div class="">
                            <div class="advertise__details_field">
                                <label for="colour" class="required">Colour:</label>
                                <div>
                                    <select name="colour" id="colour" class="form-control" :value="selectedColour" v-model="selectedColour">
                                        <option value="">Please Select</option>
                                        <option v-for="(val, idx) in colours" v-bind:key="idx" :value="val.id">{{ val.colour }}</option>
                                    </select>
                                    <p v-if="errorResponse.colour_id" class="form-error">{{errorResponse.colour_id[0]}}</p>
                                </div>
                            </div>
                            <div class="advertise__details_field">
                                <label for="bodytype" class="required">Doors:</label>
                                <div>
                                    <select name="doors" id="doors" class="form-control" :value="selectedDoors" v-model="selectedDoors">
                                        <option value="">Please Select</option>
                                        <option v-for="(val, idx) in doors" v-bind:key="idx" :value="val">{{ val > 1 ? val + ' Doors' : val + ' Door' }}</option>
                                    </select>
                                    <p v-if="errorResponse.doors" class="form-error">{{errorResponse.doors[0]}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="advertise__details_field">
                            <label for="mileage" class="required">Mileage:</label>
                            <div>
                                <input type="text" name="mileage" id="mileage" placeholder="Mileage" v-model="selectedMileage"  class="form-control" @keypress="isNumber($event),resetError($event)" />
                                <div class="advertise__details_mileagetoggle" data-class="mileage-toggle">
                                    <a href="javascript:void(0)" :class="{ active: isMileageActive }" @click="toggleMileage('mi')">mi</a> / <a href="javascript:void(0)" :class="{ active: !isMileageActive }" @click="toggleMileage('km')">km</a>
                                </div>
                                <p v-if="errorResponse.mileage" class="form-error">{{errorResponse.mileage[0]}}</p>
                                <div v-if="selectedMileage > 0 && !errorResponse.mileage" class="odometer-converted">{{showConvertedOdometer}}</div>
                            </div>
                        </div>

                        <div class="advertise__details_field">
                            <label for="asking_price" class="required">Asking Price:</label>
                            <div>
                                <input type="text" name="asking_price" id="asking_price" placeholder="Asking Price*" v-model="selectedAskingPrice" class="form-control" @keypress="isNumber($event),resetError($event)"/>
                                <p v-if="errorResponse.asking_price" class="form-error">{{errorResponse.asking_price[0]}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="advertise__details_mainbottomright">
                        <div class="">
                            <div class="advertise__details_field">
                                <label for="fueltype" class="required">Fuel Type:</label>
                                <div>
                                    <select name="fueltype" id="fueltype" class="form-control" :value="selectedFuelType" v-model="selectedFuelType"  @change="checkEngineCC">
                                        <option value="">Please Select</option>
                                        <option v-for="(val, idx) in fuelTypes" v-bind:key="idx" :value="val.id">{{ val.fuel_type }}</option>
                                    </select>
                                    <p v-if="errorResponse.fuel_type_id" class="form-error">{{errorResponse.fuel_type_id[0]}}</p>
                                </div>
                            </div>
                            <div class="advertise__details_field">
                                <label for="engineCapacity" id="engineCCLabel" class="required">Engine Capacity:</label>
                                <div>
                                    <select name="engineCapacity" id="engineCapacity" class="form-control" :value="selectedEngineCapacity" v-model="selectedEngineCapacity" >
                                        <option value="" v-if="selectedFuelType != 4">Please Select</option>
                                        <option v-if="selectedFuelType != 4" v-for="(val, idx) in engineSizes" v-bind:key="idx" :value="(parseFloat(val) * 1000).toFixed(0)">{{ val }} Litres</option>
                                    </select>
                                    <p v-if="errorResponse.engine_capacity" class="form-error">{{errorResponse.engine_capacity[0]}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="advertise__details_field">
                                <label for="bodytype" class="required">Body Type:</label>
                                <div>
                                    <select name="bodytype" id="bodytype" class="form-control" :value="selectedBodyType" v-model="selectedBodyType">
                                        <option value="">Please Select</option>
                                        <option v-for="(val, idx) in bodyTypes" v-bind:key="idx" :value="val.id">{{ val.body_type }}</option>
                                    </select>
                                    <p v-if="errorResponse.body_type_id" class="form-error">{{errorResponse.body_type_id[0]}}</p>
                                </div>
                            </div>
                            <div class="advertise__details_field">
                                <label for="colour">Seats:</label>
                                <div>
                                    <input type="text" name="seats" id="seats" placeholder="" v-model="selectedSeats" class="form-control" @keypress="isNumber($event),resetError($event)"/>
                                    <p v-if="errorResponse.seats" class="form-error">{{errorResponse.seats[0]}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="advertise__details_mobilecolumn">
                            <div class="advertise__details_field">
                                <label for="owners">Previous Owners:</label>
                                <div>
                                    <input type="text" name="owners" id="owners" placeholder="" class="form-control" v-model="selectedOwners" @keypress="isNumber($event),resetError($event)"/>
                                    <p v-if="errorResponse.owners" class="form-error">{{errorResponse.owners[0]}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="advertise__details_field">
                            <div class="advertise__details_column">
                                <label for="nct_month">NCT Due:</label>
                                <select name="nct_month" id="nct_month" class="form-control" v-model="selectedNctMonth">
                                    <option :value="null" selected="selected" disabled="disabled">Month</option>
                                    <option v-for="(val, idx) in months" v-bind:key="idx" :value="idx + 1">{{ val }}</option>
                                </select>
                                <select name="nct_year" id="nct_year" class="form-control" v-model="selectedNctYear">
                                    <option :value="null" selected="selected" disabled="disabled">Year</option>
                                    <option v-for="(val, idx) in years24" v-bind:key="idx" :value="val">{{ val }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="advertise__details_field">
                            <div class="advertise__details_column">
                                <label for="tax_month">Tax Due:</label>
                                <select name="tax_month" id="tax_month" class="form-control" v-model="selectedTaxMonth">
                                    <option :value="null" selected="selected" disabled="disabled">Month</option>
                                    <option v-for="(val, idx) in months" v-bind:key="idx" :value="idx+1">{{ val }}</option>
                                </select>
                                <select name="tax_year" id="tax_year" class="form-control" v-model="selectedTaxYear">
                                    <option :value="null" selected="selected" disabled="disabled">Year</option>
                                    <option v-for="(val, idx) in years24" v-bind:key="idx" :value="val">{{ val }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="advertise__details_spec">
                    <div class="vehicle-description">
                        <h2>Vehicle Features / Description</h2>
                        <textarea class="form-control" v-model="selectedDescription"></textarea>
                    </div>
                    <div class="advertise__details_spectop">

                        <div class="advertise__details_accessories">
                            <h2>Comfort &amp; Accessories</h2>
                            <div class="advertise__details_specwrapper">
                                <div class="advertise__details_spec_mycol">
                                    <div class="advertise__details_spec_fields" v-for="(item, idx) in comfort" v-bind:key="idx">
                                        <input type="checkbox" class="custom-checkbox" :id="idx+'_comfort'" :name="idx+'_comfort'" :value="item.id" v-model="selectedComfort"/>
                                        <label :for="idx+'_comfort'" class="custom-checkbox-label"></label>
                                        <label :for="idx+'_comfort'"> {{ item.description }} </label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="advertise__details_styling">
                            <h2>Styling &amp; Appearance</h2>
                            <div class="advertise__details_specwrapper">
                                <div class="advertise__details_spec_fields" v-for="(item,idx) in styling" v-bind:key="idx">
                                    <input type="checkbox" class="custom-checkbox" :id="idx+'_styling'" :name="idx+'_styling'" :value="item.id" v-model="selectedStyling"/>
                                    <label :for="idx+'_styling'" class="custom-checkbox-label"></label>
                                    <label :for="idx+'_styling'">{{ item.description }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="advertise__details_specbottom">
                        <h2>Security &amp; Safety</h2>
                        <div class="advertise__details_specwrapper">
                            <div class="advertise__details_spec_mycol advertise__details_spec_mycol--specbottom">
                                <div class="advertise__details_spec_fields" v-for="(item, idx) in security" v-bind:key="idx">
                                    <input type="checkbox" class="custom-checkbox" :id="idx+'_security'" :name="idx+'_security'" :value="item.id" v-model="selectedSecurity"/>
                                    <label :for="idx+'_security'" class="custom-checkbox-label"></label>
                                    <label :for="idx+'_security'">{{ item.description }}</label>
                                </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="advertise__details_contact">
                    <h2>Contract Person</h2>
                    <div class="advertise__details_contact_wrapper">
                        <div class="advertise__details_contact_left">
                            <div class="advertise__details_field">
                                <label for="contact_name" class="required">Full Name:</label>
                                <div>
                                    <input type="text" name="contact_name" id="contact_name" class="form-control" placeholder="Contact Name*" v-model="selectedContactName" @keypress="resetError($event)"/>
                                    <p v-if="errorResponse.contact_name" class="form-error">{{errorResponse.contact_name[0]}}</p>
                                </div>
                            </div>
                            <div class="advertise__details_suboption">
                                <label for="contact_name_display">Display in advert?</label>
                                <input type="checkbox" class="custom-checkbox" name="display_name_in_advert"
                                       id="display_name_in_advert" v-model="selectedDisplayName" @click="resetError($event)"/>
                                <label for="display_name_in_advert" class="custom-checkbox-label"></label>
                                <p style="text-align: end" v-if="errorResponse.display_name_in_advert" class="form-error"><br>{{errorResponse.display_name_in_advert[0]}}</p>
                            </div>
                            <div class="advertise__details_field">
                                <label for="phone" class="required">Phone:</label>
                                <div>
                                    <input type="text" name="phone" id="phone" class="form-control" placeholder="Contact Number*" v-model="selectedPhone" @keypress="resetError($event)"/>
                                    <p v-if="errorResponse.phone" class="form-error">{{errorResponse.phone[0]}}</p>
                                </div>
                            </div>
                            <div class="advertise__details_suboption">
                                <label for="contact_phone_display">Display in advert?</label>
                                <input type="checkbox" class="custom-checkbox" name="display_phone_in_advert"
                                       id="display_phone_in_advert" v-model="selectedDisplayPhone" @click="resetError($event)"/>
                                <label for="display_phone_in_advert" class="custom-checkbox-label"></label>
                                <p style="text-align: end" v-if="errorResponse.display_phone_in_advert" class="form-error"><br>{{errorResponse.display_phone_in_advert[0]}}</p>
                            </div>
                        </div>
                        <div class="advertise__details_contact_right">
                            <div class="advertise__details_field">
                                <label for="email" class="required">Email:</label>
                                <div>
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email Address*" v-model="selectedEmail" @keypress="resetError($event)"/>
                                    <p v-if="errorResponse.email" class="form-error">{{errorResponse.email[0]}}</p>
                                </div>
                            </div>
                            <div class="advertise__details_suboption">
                                <label for="allow_email">Allow contact by email?</label>
                                <input type="checkbox" class="custom-checkbox" name="allow_contact_by_email" id="allow_contact_by_email" v-model="selectedAllowEmail" @click="resetError($event)"/>
                                <label for="allow_contact_by_email" class="custom-checkbox-label"></label>
                                <p style="text-align: end" v-if="errorResponse.allow_contact_by_email" class="form-error"><br>{{errorResponse.allow_contact_by_email[0]}}</p>
                            </div>
                            <div class="advertise__details_field">
                                <label for="car_location_id" class="required">Location of Car:</label>
                                <div>
                                    <select name="car_location_id" id="car_location_id" class="form-control"  v-model="selectedCarLocation" @change="resetError($event)">
                                        <option :value="null" selected="selected">Location*</option>
                                        <option v-for="(val, idx) in locations" v-bind:key="idx" :value="val.id">{{ val.region }}</option>
                                    </select>
                                    <p v-if="errorResponse.car_location_id" class="form-error">{{errorResponse.car_location_id[0]}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="advertise__details_terms">
                        <input type="checkbox" class="custom-checkbox" name="user_terms" id="user_terms" v-model="selectedUserTerms" @click="resetError($event)"/>
                        <label for="user_terms" class="custom-checkbox-label"></label>
                        <div>
                            <label for="terms">I have read and agree to the <a href="/terms" target="_blank">User Terms</a></label>
                            <p v-if="errorResponse.user_terms" class="form-error fr-terms">{{errorResponse.user_terms[0]}}</p>
                        </div>
                    </div>
                    <div class="advertise__buttons advertise__buttons--desktoponly">
                        <button class="advertise__button" v-on:click="saveForm()" data-cy="submit-form">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="advertise__existing-ad--desktoponly">
            <router-link class="advertise__link" :to="{name: 'advertise-manage'}">Manage Existing Ads</router-link>
        </div>

        <div class="advertise__buttons advertise__buttons--mobileonly" v-if="showForm">
            <button class="advertise__button" v-on:click="saveForm()" data-cy="submit-form-mobile">Next</button>
        </div>


    </div>
</template>

<script>
import NativeEventBus from "../../native-event-bus";
import {mapMutations} from "vuex";
    export default {
        name: "RegDetails",
        data: function () {
            return {
                errorResponse500:[],
                lookupError: false,
                adId:null,
                years: this.getYearsRange(),
                makes: [],
                models: [],
                transmissionTypes: [],
                engineSizes: [],
                bodyTypes: [],
                colours: [],
                doors: [],
                fuelTypes:[],
                months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                years24: this.getYearsRange24(),
                selectedMake: null,
                selectedModel: null,
                selectedFuelType: null,
                selectedYear:null,
                selectedTransmissionType:null,
                selectedEngineCapacity:null,
                selectedSeats:null,
                selectedOwners:null,
                selectedContactName:null,
                selectedPhone:null,
                selectedDisplayPhone:true,
                selectedAllowEmail:true,
                selectedEmail:null,
                selectedDisplayName:true,
                selectedCarLocation: null,
                selectedBodyType:null,
                selectedColour:null,
                selectedDoors:null,
                selectedMileage:null,
                selectedVariant:null,
                selectedAskingPrice:null,
                selectedUserTerms:false,
                selectedDescription:null,
                selectedNctMonth:null,
                selectedNctYear:null,
                selectedTaxMonth:null,
                selectedTaxYear:null,
                comfort:[],
                styling:[],
                security:[],
                regLookup: {},
                locations:null,
                registration: '',
                showForm: false,
                showResult: false,
                lookupMake: false,
                lookupModel:false,
                lookupYear:false,
                lookupFuelType:false,
                lookupDoors:false,
                lookupEngine:false,
                engineCapacity: false,
                lookupTransmissionType: false,
                lookupColour:false,
                lookupBodyType:false,
                errorResponse:[],
                selectedComfort: [],
                selectedStyling: [],
                selectedSecurity: [],
                isMileageActive: false,
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString()
                const words = value.split(" ");

                for (let i = 0; i < words.length; i++) {
                    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
                }
                return words.join(" ");
            }
        },
        mounted() {

            if(this.$route.query.start == 1){
                sessionStorage.removeItem('adId');
                sessionStorage.removeItem('editMode');
                sessionStorage.removeItem('key');
                sessionStorage.removeItem('images');
            }

            this.loadFormData();

            window.NativeEventBus1 = new NativeEventBus;
            window.NativeEventBus1.addEventListener('disableResult',  () => {
                this.disableForm();
            });

            window.NativeEventBus1.addEventListener('enableRegForm',  () => {
                if(sessionStorage.getItem('adId') && sessionStorage.getItem('adId') > 0) {
                    this.loadAd(sessionStorage.getItem('adId'));
                    this.enableForm();
                }
            });
        },
        computed:{
            getAdId(){
                if (typeof window !== 'undefined') {
                    return sessionStorage.getItem('adId');
                }

                return null;
            },
            editMode(){
                if (typeof window !== 'undefined') {
                    return parseInt(sessionStorage.getItem('adId') || 0) > 0;
                }else{
                    return true;
                }
            },
            showConvertedOdometer(){
                // conversion factor
                const factor = 0.621371;
                let res = 0;

                if(this.isMileageActive){

                    // calculate miles
                    res = this.selectedMileage / factor;

                }else{
                    // calculate miles
                    res = this.selectedMileage * factor;
                }
                const label = this.isMileageActive ? ' km' : ' mi';
                return Number(Math.round(res, 0)).toLocaleString().toString() + label;
            }
        },
        methods:{
            ...mapMutations([
                'setIsLoading'
            ]),
            checkEngineCC(event){
                let value = null;
                if(typeof event == 'object'){
                    value = event.target.value;
                }else if(typeof event == 'number'){
                    value = event;
                }
                //Electric | id=4
                if(value == 4){
                    this.selectedEngineCapacity = '';
                    document.getElementById('engineCCLabel').classList.remove('required');
                    document.getElementById('engineCapacity').setAttribute('readonly', 'readonly');
                    document.getElementById('engineCapacity')
                }else{
                    document.getElementById('engineCapacity').removeAttribute('readonly');
                    document.getElementById('engineCCLabel').classList.add('required');
                }
            },
            /**
             * Return year array range from -2 years to +4 years
             * @returns {array[]}
             */
            getYearsRange24(){
                const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
                return range(new Date().getFullYear() - 2, new Date().getFullYear() + 4, 1).reverse();
            },
            /**
             * Return an array of years starting in 1923.
             * @returns {array[]}
             */
            getYearsRange(){
                const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
                return range(1923, new Date().getFullYear(), 1).reverse();
            },
            toggleMileage(value){
                if(value == 'km') {
                    this.isMileageActive = false;
                }else if (value == 'mi'){
                    this.isMileageActive = true;
                }

            },
            loadAd(adId){
                this.setIsLoading(true);
                this.axios.get(process.env.VUE_APP_API_URL + '/api/advertise/preview/' + adId, {})
                    .then(response => {
                        this.registration = response.data.ad.reg_number;
                        this.selectedMake = response.data.ad.make_id;
                        this.models = response.data.ad.models;
                        this.selectedModel = response.data.ad.model_id;
                        this.selectedVariant = response.data.ad.variant;
                        this.selectedYear = response.data.ad.reg_year;
                        this.selectedTransmissionType = response.data.ad.transmission_type_id;
                        this.selectedColour = response.data.ad.colour_id;
                        this.selectedDoors = response.data.ad.doors;
                        this.selectedMileage = response.data.ad.mileage;
                        this.selectedAskingPrice = (parseFloat(response.data.ad.price)).toFixed(0);
                        this.selectedFuelType = response.data.ad.fuel_type_id;
                        this.selectedEngineCapacity = response.data.ad.engine_cc;
                        this.selectedBodyType = response.data.ad.body_type_id;
                        this.selectedSeats = response.data.ad.seats;
                        this.selectedOwners = response.data.ad.owners;
                        this.isMileageActive = response.data.ad.odo_units === 'miles';
                        this.selectedDescription = response.data.ad.description;
                        this.selectedCarLocation = response.data.ad.region_id;

                        this.regLookup.engine = response.data.ad.engine_cc;
                        this.regLookup.model = response.data.ad.model.model;
                        this.regLookup.fuel_type = response.data.ad.fuel_type.fuel_type;
                        this.regLookup.transmission_type = response.data.ad.transmission_type.transmission_type;
                        this.regLookup.colour = response.data.ad.colour.colour;
                        this.lookupColour = response.data.ad.colour.colour;
                        this.regLookup.body_type = response.data.ad.body_type.body_type;

                        if(response.data.ad.tax_due_date){
                            let taxDate = new Date(response.data.ad.tax_due_date);
                            this.selectedTaxMonth = taxDate.getMonth();
                            this.selectedTaxYear = taxDate.getFullYear();
                        }

                        if(response.data.ad.test_due_date){
                            let nctDate = new Date(response.data.ad.test_due_date);
                            this.selectedNctMonth = nctDate.getMonth();
                            this.selectedNctYear = nctDate.getFullYear();
                        }

                        let comfortFilters = response.data.ad.feature.filter( val => val.section_id == 1)
                        if(comfortFilters.length > 0){
                            this.selectedComfort = comfortFilters.map(val => val.id )
                        }

                        let stylingFilters = response.data.ad.feature.filter( val => val.section_id == 3)
                        if(stylingFilters.length > 0){
                            this.selectedStyling = stylingFilters.map(val => val.id )
                        }

                        let securityFilters = response.data.ad.feature.filter( val => val.section_id == 2)
                        if(securityFilters.length > 0){
                            this.selectedSecurity = securityFilters.map(val => val.id )
                        }

                        this.selectedContactName = response.data.ad.adv_settings.contact_name;
                        this.selectedDisplayName = response.data.ad.adv_settings.show_name;
                        this.selectedPhone = response.data.ad.adv_settings.phone;
                        this.selectedDisplayPhone = response.data.ad.adv_settings.show_phone;
                        this.selectedEmail = response.data.ad.adv_settings.email;
                        this.selectedAllowEmail = response.data.ad.adv_settings.email_optin;
                        this.selectedUserTerms = true;

                        if(this.selectedFuelType == 4){
                                this.checkEngineCC(this.selectedFuelType);
                        }

                        this.setIsLoading(false);

                    })
                    .catch(error => {
                        console.error(error);
                        this.setIsLoading(false);
                    });
            },
            loadFormData(){
                this.setIsLoading(true);
                this.axios.get(process.env.VUE_APP_API_URL + '/api/advertise/load-data', {})
                    .then(response => {
                        this.makes = response.data.makes;
                        this.transmissionTypes = response.data.transmissionTypes;
                        this.colours = response.data.colours;
                        this.doors = response.data.doors;
                        this.fuelTypes = response.data.fuelTypes;
                        this.engineSizes = response.data.engineSizes;
                        this.bodyTypes = response.data.bodyTypes;
                        this.locations = response.data.locations;
                        this.comfort = response.data.comfort;
                        this.styling = response.data.styling;
                        this.security = response.data.security;
                        this.setIsLoading(false);

                        // Call Preview
                        if(sessionStorage.getItem('adId') && sessionStorage.getItem('adId') > 0){
                            this.loadAd(sessionStorage.getItem('adId'));
                            this.enableForm();
                        }

                    })
                    .catch(error => {
                        console.error(error.response.data);
                        this.setIsLoading(false);
                    });
            },
            saveForm(){
                window.scrollTo(0, 0);
                let postData = {
                    id: this.getAdId,
                    dealer_id: 1, //private Advertiser
                    description: this.selectedDescription,
                    reg_number: this.registration,
                    make_id: this.selectedMake,
                    model_id: this.selectedModel,
                    reg_year: this.selectedYear,
                    variant: this.selectedVariant,
                    transmission_type_id: this.selectedTransmissionType,
                    colour_id: this.selectedColour,
                    engine_capacity: this.selectedEngineCapacity,
                    mileage: this.selectedMileage,
                    body_type_id: this.selectedBodyType,
                    engine_size_id: this.selectedEngineCapacity,
                    fuel_type_id: this.selectedFuelType,
                    doors: this.selectedDoors,
                    seats: this.selectedSeats,
                    asking_price: this.selectedAskingPrice,
                    contact_name: this.selectedContactName,
                    phone: this.selectedPhone,
                    email: this.selectedEmail,
                    car_location_id: this.selectedCarLocation,
                    user_terms: this.selectedUserTerms ? 1 : 0,
                    display_name_in_advert : this.selectedDisplayName,
                    display_phone_in_advert: this.selectedDisplayPhone,
                    allow_contact_by_email: this.selectedAllowEmail,
                    owners: this.selectedOwners,
                    comfort: this.selectedComfort,
                    security: this.selectedSecurity,
                    styling: this.selectedStyling,
                    nct_month: this.selectedNctMonth,
                    nct_year: this.selectedNctYear,
                    tax_month: this.selectedTaxMonth,
                    tax_year: this.selectedTaxYear,
                    odo_units: this.isMileageActive ? 'miles' : 'km',
                    edit_key: sessionStorage.getItem('key')
                };

                this.setIsLoading(true);
                this.axios.post(process.env.VUE_APP_API_URL + '/api/advertise/save', postData)
                    .then(response => {
                        this.adId = response.data.id;
                        sessionStorage.setItem('adId', this.adId);
                        sessionStorage.setItem('key', response.data.adv_settings.password);
                        this.nextStep();
                        this.setIsLoading(false);
                   }).catch(error => {
                       if(typeof error.response.data.errors !== 'undefined'){
                           this.errorResponse = error.response.data.errors;
                       }else if(error.response.status == 500){
                            this.errorResponse500 = {'error':'Generl error! Please try again later.'};
                       }
                        this.setIsLoading(false);
                   });


            },
            loadModels(event){
                this.setIsLoading(true);
                this.selectedMake =  typeof event === 'object' ? event.target.value : event;
                this.axios.get(process.env.VUE_APP_API_URL + '/api/advertise/load-models/' + this.selectedMake, {})
                    .then(response => {
                        this.models = response.data.models;

                        //Update Model from lookup
                        this.lookupModel = this.models.find((model) => {
                            return model != null && model.model!= null && model.model.toLowerCase() == this.regLookup.model.toLowerCase();
                        });

                        this.lookupFuelType = this.fuelTypes.find((val) => {
                            return val.fuel_type.toLowerCase() == this.regLookup.fuel_type.toLowerCase();
                        });

                        this.lookupTransmissionType = this.transmissionTypes.find((val) => {
                            return val.transmission_type.toLowerCase() == this.regLookup.transmission_type.toLowerCase();
                        });

                        this.lookupColour = this.colours.find((val) => {
                            return val.colour.toLowerCase() == this.regLookup.colour.toLowerCase();
                        });

                        this.lookupBodyType = this.bodyTypes.find((val) => {
                            return val.body_type.toLowerCase() == this.regLookup.body_type.toLowerCase();
                        });

                        if(Object.keys(this.lookupFuelType).length > 0){
                            this.selectedFuelType = this.lookupFuelType.id;
                        }

                        if(this.lookupTransmissionType && Object.keys(this.lookupTransmissionType).length > 0){
                            this.selectedTransmissionType = this.lookupTransmissionType.id;
                        }

                        if(Object.keys(this.lookupColour).length > 0){
                            this.selectedColour = this.lookupColour.id;
                        }

                        if(Object.keys(this.lookupBodyType).length > 0){
                            this.selectedBodyType = this.lookupBodyType.id;
                        }

                        this.selectedEngineCapacity = this.regLookup.engine ? this.regLookup.engine_cc_num  : '';

                        let nctDate = this.regLookup.test_due_date ? new Date(this.regLookup.test_due_date) : '';
                        let taxDate = this.regLookup.tax_due_date ?  new Date(this.regLookup.tax_due_date) : '';

                        this.selectedNctMonth = nctDate instanceof Date ? nctDate.getMonth() : '';
                        this.selectedNctYear = nctDate instanceof Date ? nctDate.getFullYear() : '';
                        this.selectedTaxMonth = taxDate instanceof Date ? taxDate.getMonth() : '';
                        this.selectedTaxYear = taxDate instanceof Date ? taxDate.getFullYear() : '';

                        this.setIsLoading(false);

                        this.$nextTick(()=>{
                            if(typeof this.lookupModel == 'object' && Object.keys(this.lookupModel).length > 0){
                                this.selectedModel = this.lookupModel.id;
                            }else {
                                this.selectedModel = "";
                            }
                        });
                    })
                    // .catch(error => {
                    //     console.error(error.response.data);
                    //     this.setIsLoading(false);
                    // })
            },
            isNumber ($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                    $event.preventDefault();
                }
            },
            resetError($event){
                delete this.errorResponse[$event.target.id];
            },
            lookup(event){

                event.preventDefault();

                if(this.registration){
                    this.setIsLoading(true);
                    this.axios.post(process.env.VUE_APP_API_URL + '/api/advertise/reg-lookup', {
                        'reg': this.registration,
                    })
                        .then(response => {
                            this.showResult = true;
                            this.showForm = false;
                            this.regLookup = response.data;
                            this.registration = this.regLookup.reg_number;
                            this.lookupDoors = this.regLookup.doors;
                            this.engineCapacity = (parseFloat(this.regLookup.engine)).toFixed(1);
                            this.lookupMake = this.makes.find((make) => {
                                return make.make.toLowerCase() == this.regLookup.make.toLowerCase();
                            });

                            if(this.regLookup){
                                if(Object.keys(this.lookupMake).length > 0){
                                    this.selectedMake = this.lookupMake.id;
                                    this.loadModels(this.selectedMake);
                                }
                                this.selectedYear = this.regLookup.reg_year;
                                this.selectedDoors = this.lookupDoors;
                                this.selectedEngineCapacity = this.engineCapacity;
                                this.selectedVariant = this.regLookup.variant;
                                this.selectedComfort = this.regLookup.specs.comfort;
                                this.selectedStyling = this.regLookup.specs.styling;
                                this.selectedSecurity = this.regLookup.specs.security;
                                this.selectedDoors = this.regLookup.doors;
                                this.selectedSeats = this.regLookup.seats;
                                this.selectedOwners = this.regLookup.owners;
                                this.selectedDoors = this.lookupDoors;
                                this.selectedVariant = this.regLookup.variant;
                            }


                            this.setIsLoading(false);

                        })
                        .catch(error => {
                            console.error(error);
                            this.lookupError = 'Car Registration not found';
                            this.setIsLoading(false);
                        });

                }else{
                    this.lookupError = 'Please, provide a car reg';
                }


                window.scrollTo(0, 0);
            },
            disableForm(){
                if(!this.showResult && this.showForm){
                    this.showResult = true;
                    this.showForm = false;
                }else{
                    this.disableResult();
                }

                window.scrollTo(0, 0);
            },
            enableForm(){
                this.showResult = false;
                this.showForm = true;
                // window.scrollTo(0, 0);
            },
            disableResult(){
                this.showResult = false;
                this.showForm = false;
                window.scrollTo(0, 0);
            },
            nextStep() {
                window.NativeEventBus.dispatchEvent("nextStep");
            }
        }
    }
</script>

<style scoped>
</style>
